import Vue from "vue";
import Router from "vue-router";
import NewPlayer from "./views/NewPlayer";
import WaitingRoom from "./views/WaitingRoom";
import Game from "./views/Game";
import EndGame from "./views/EndGame";

Vue.use(Router);

export default new Router({
  mode: "history",
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "newPlayer",
      components: {
        default: NewPlayer
      }
    },
    {
      path: "/waitingRoom",
      name: "waitingRoom",
      components: {
        default: WaitingRoom
      }
    },
    {
      path: "/game",
      name: "game",
      components: {
        default: Game
      }
    },
    {
      path: "/end",
      name: "end",
      components: {
        default: EndGame
      }
    }
  ]
});
