<template>
  <section
    class="section section-shaped section-hero my-0"
    v-if="isGameEnded || isAdmin"
  >
    <div class="container align-items-center">
      <div class="col px-0">
        <div class="row justify-content-center">
          <div class="col-sm-12 text-center">
            <p class="text-white h1">
              Game Over!
            </p>
            <p>
              <base-button
                type="success"
                class="my-4"
                icon="ni ni-basket"
                @click="clickConsolationPrize"
                :disabled="!isAdmin"
                v-if="isAdmin && playerScores.length > 3"
              >
                Consolation Prize
              </base-button>
              <base-button
                type="danger"
                class="my-4"
                icon="ni ni-favourite-28"
                @click="clickLeave"
                :disabled="!isAdmin"
                v-if="isAdmin"
              >
                Leave Game
              </base-button>
            </p>
            <card type="secondary">
              <p class="h2">
                <i class="fa fa-trophy text-gold"></i> Leaderboard
                <i class="fa fa-trophy text-gold"></i>
              </p>
              <ul class="list-unstyled leaderboard col-sm-12">
                <li
                  v-for="(playerScore, index) in playerScores"
                  v-bind:key="playerScore.name"
                  class="btn btn-secondary row mr-0 ml-0 mb-2"
                >
                  <div class="row mr-0 ml-0">
                    <div class="pr-2 pl-2">
                      <i
                        class="fa fa-2x fa-star"
                        v-bind:class="leaderboardClass(index)"
                        v-if="index < 3"
                      ></i>
                      <badge :circle="true" type="warning" v-if="index > 2">{{
                        index + 1
                      }}</badge>
                    </div>

                    <div class="h5 mb-0 text-left">
                      {{ playerScore.name }}
                    </div>
                    <div class="pr-2 pl-2">
                      <badge :circle="true" type="primary">
                        {{ playerScore.score }}
                      </badge>
                    </div>
                  </div>
                </li>
              </ul>
            </card>
          </div>
        </div>
      </div>
    </div>

    <modal
      :show.sync="showModal"
      gradient="secondary"
      modal-classes="modal-secondary modal-dialog-centered modal-lg"
    >
      <div class="py-3 text-center h1">
        Congratulations
        <b
          ><span class="b"
            ><Blink :duration="300" class="text-orange">{{
              playerScores.length ? playerScores[0].name : "Nobody"
            }}</Blink></span
          ></b
        >!
        <p class="mb-0 h4">You're a winner!</p>
      </div>
    </modal>

    <modal
      :show.sync="showConsolation"
      gradient="secondary"
      modal-classes="modal-secondary modal-dialog-centered modal-lg"
    >
      <div class="py-3 text-center h1" v-if="playerScores.length <= 3">
        No consolation prizes this round!
      </div>
      <div v-if="playerScores.length > 3">
        <div class="text-center h3" v-if="playerScores.length > 3">
          {{
            consolationWinnerName
              ? "And the winner is... (sorta)"
              : "Picking a winner..."
          }}
        </div>
        <div
          class="text-center h1 text-warning"
          v-if="playerScores.length > 3"
          @click="clickRevealName()"
        >
          {{
            !consolationWinnerName
              ? consolationWinner.name
              : clickReveal
              ? `${consolationWinnerName}!`
              : "Click to reveal..."
          }}
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Blink from "vue-blink";

export default {
  components: {
    Modal,
    Blink
  },

  data() {
    return {
      showModal: true,
      showConsolation: false,
      consolationWinner: "",
      consolationInterval: null,
      consolationWinnerName: "",
      clickReveal: false
    };
  },

  mounted() {
    let isGameEnded = this.$store.state.isGameEnded;
    let isAdmin = this.$store.state.isAdmin;
    if (!isGameEnded && !isAdmin) {
      console.log("really?? get out of here!");
      this.$router.push("/");
    } else if (isGameEnded) {
      this.$confetti.start();

      setTimeout(() => {
        this.showModal = false;
        this.$confetti.stop();
      }, 5000);
    }
  },

  computed: {
    playerScores() {
      return this.$store.state.playerScores;
    },
    isGameEnded() {
      return this.$store.state.isGameEnded;
    },
    isAdmin() {
      return this.$store.state.isAdmin;
    },
    players() {
      return this.$store.state.players;
    }
  },

  methods: {
    leaderboardClass(index) {
      if (index === 0) return "text-gold";
      if (index === 1) return "text-silver";
      if (index === 2) return "text-bronze";
    },
    clickLeave() {
      if (!this.isAdmin) return;
      location.href = "/";
    },
    clickConsolationPrize() {
      if (this.playerScores.length < 4) return;

      this.showConsolation = true;
      let potentials = this.playerScores.slice(3);
      this.$socket.emit("startConsolation", potentials);

      let index = 0;
      this.consolationInterval = setInterval(() => {
        this.consolationWinner = potentials[index++ % potentials.length];
      }, 100);
    },
    clickRevealName() {
      if (!this.consolationWinnerName) return;
      this.clickReveal = true;
    }
  },

  sockets: {
    consolationWinner(winner) {
      clearInterval(this.consolationInterval);
      this.consolationWinnerName = this.players[winner].name;
    }
  }
};
</script>

<style>
.text-orange {
  color: rgb(185, 99, 0);
}

.text-gold {
  color: rgb(255, 217, 0);
}

.text-silver {
  color: rgb(196, 196, 196);
}

.text-bronze {
  color: rgb(177, 139, 69);
}

.va-m {
  vertical-align: middle;
}

.leaderboard li {
  display: block;
}

.leaderboard .row {
  justify-content: space-between;
}

.leaderboard .btn {
  text-transform: none;
}

.leaderboard .btn:not(:last-child) {
  margin-right: 0;
}
</style>
