<template>
  <div id="app" class="section section-shaped my-0 py-0">
    <div class="shape shape-style-1 bg-gradient-default">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <router-view name="header"></router-view>
    <fade-transition origin="center" mode="out-in" :duration="250">
      <router-view />
    </fade-transition>
    <router-view name="footer"></router-view>

    <modal
      :show="isDisconnected"
      gradient="warning"
      modal-classes="modal-warning modal-dialog-centered modal-sm"
      type="notice"
    >
      <div class="py-3 text-center">
        <i class="ni ni-atom ni-3x fas fa-spin"></i>
        <h5 class="text-white mt-4">Attempting to connect...</h5>
      </div>
    </modal>

    <modal
      :show.sync="showConsolationModal"
      :gradient="isWinner ? 'success' : 'danger'"
      modal-classes="modal-warning modal-dialog-centered modal-sm"
    >
      <div class="py-3 text-center">
        <i class="ni ni-atom ni-3x fas fa-spin"></i>
        <h3 class="text-white mt-4">
          {{ isWinner ? "You're a winner (kinda)!" : "Sorry, not today :(" }}
        </h3>
      </div>
    </modal>
  </div>
</template>

<script>
import { FadeTransition } from "vue2-transitions";
import Modal from "./components/Modal";

export default {
  components: {
    FadeTransition,
    Modal
  },

  data() {
    return {
      showConsolationModal: false,
      isWinner: false
    };
  },

  computed: {
    inGame() {
      return this.$store.state.isReady && this.$store.state.isGameStarted;
    },
    isDisconnected() {
      return !this.$store.state.isConnected;
    },
    isAdmin() {
      return this.$store.state.isAdmin;
    }
  },

  sockets: {
    connect() {
      this.$store.commit("updateIsConnected", true);
      this.$store.commit("updateId", this.$socket.id);

      this.$gtag.event("connect", { method: "socket.io" });
    },
    disconnect() {
      this.$store.commit("resetState");
      this.$router.push("/");
      this.$store.commit("updateIsConnected", false);
    },
    reconnect() {
      this.$store.commit("updateIsConnected", true);
    },
    roomClosing() {
      this.$store.commit("resetState");
      this.$store.commit("updateIsConnected", this.$socket.connected);
      this.$router.push("/");
    },
    userConnected(players) {
      this.$store.commit("updatePlayers", players);
    },
    userDisconnected(players) {
      this.$store.commit("updatePlayers", players);
    },
    gameStarted(gameStarted) {
      this.$store.commit("updateGameStarted", gameStarted);
    },
    gameStarting(gameStarting) {
      this.$store.commit("updateGameStarting", gameStarting);
    },
    startingIn(startingIn) {
      this.$store.commit("updateStartingIn", startingIn);
    },
    gameReset() {
      location.reload();
    },
    totalRounds(totalRounds) {
      this.$store.commit("updateTotalRounds", totalRounds);
    },
    currentQuestion(data) {
      this.$store.commit("updateCurrentSelected", "");
      this.$store.commit("updateCorrectAnswer", "");
      this.$store.commit("updateCurrentRound", data[0]);
      this.$store.commit("updateCurrentQuestion", data[1]);
      this.$store.commit("updateCurrentAnswers", data[2]);
      this.$store.commit("updateIsLastRound", data[3]);
    },
    playerAnswers(answers) {
      this.$store.commit("updatePlayerAnswers", answers);
    },
    roundEnded(roundEndedData) {
      this.$store.commit("updateRoundEnded", roundEndedData[0]);
      this.$store.commit("updateCorrectAnswer", roundEndedData[1]);
      this.$store.commit("updatePlayerScores", roundEndedData[2]);
    },
    gameEnded(gameEndedData) {
      this.$store.commit("updateIsGameEnded", gameEndedData[0]);
      this.$store.commit("updatePlayerScores", gameEndedData[1]);
    },
    consolationWinner(winner) {
      this.isWinner = this.$socket.id === winner;
      if (!this.isAdmin && this.inGame) {
        this.showConsolationModal = true;
      }
    }
  }
};
</script>

<style>
body {
  background: linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important;
}

#app {
  height: 100%;
}
.section-hero {
  height: 100% !important;
}

input {
  font-size: 16px !important;
}
</style>
