<template>
  <section class="section section-shaped my-0" v-if="isGameStarted || isAdmin">
    <div class="container align-items-center pt-5">
      <div class="col px-0">
        <div class="row justify-content-center">
          <div class="col-sm-12">
            <div class="row justify-content-center" v-if="!isAdmin">
              <h4 class="text-white">{{ playerName }}</h4>
            </div>
            <div class="row justify-content-center" v-if="isAdmin">
              <span class="h2 font-weight-600 text-warning letter-spacing-wide"
                >{{ roomCode }}
              </span>
            </div>

            <card type="" class="row mb-3">
              <div class="row">
                <p class="display-4 col-sm-12 pb-2 text-strong text-center">
                  ({{ currentRound }}/{{ totalRounds }}) {{ currentQuestion }}
                </p>
              </div>
              <div class="row">
                <div
                  class="col-sm-6 pb-2"
                  v-for="(answer, index) in currentAnswers"
                  v-bind:key="answer.id"
                >
                  <base-button
                    :type="getType(index)"
                    size="lg"
                    class="btn-block mb-3 unset-whitespace fh"
                    :disabled="
                      isRoundEnded ||
                        isAdmin ||
                        !isReady ||
                        currentSelected != ''
                    "
                    @click="selectedAnswer(index)"
                  >
                    {{ index }}) {{ answer }}
                  </base-button>
                </div>
              </div>
            </card>

            <card type="" class="row mb-3" v-if="isAdmin">
              <div class="row">
                <p class="h4 col-sm-12 pb-0 mb-0 text-strong text-center">
                  Results ({{ totalAnswered }}/{{ totalPlayers }})
                </p>
              </div>
              <div class="row text-left">
                <div
                  class="col-sm-12"
                  v-for="(_, index) in currentAnswers"
                  v-bind:key="index"
                >
                  <base-progress
                    :type="getType(index, true)"
                    :label="`${index} (${playerAnswers[index]})`"
                    :value="getAnswerPercentage(index)"
                    :animated="true"
                  >
                  </base-progress>
                </div>
              </div>
            </card>

            <div class="row justify-content-center">
              <base-button
                type="danger"
                :disabled="isRoundEnded"
                v-if="isAdmin && !(isLastRound && isRoundEnded)"
                @click="endRound"
                >End Round</base-button
              >
              <base-button
                type="primary"
                v-if="isAdmin && isLastRound && isRoundEnded"
                @click="endGame()"
                >End Game</base-button
              >
              <base-button
                type="info"
                v-if="isAdmin || isRoundEnded"
                :disabled="!isRoundEnded"
                @click="showLeaderboard = true"
                >Show Leaderboard</base-button
              >
              <base-button
                type="primary"
                :disabled="!isRoundEnded"
                v-if="isAdmin && !isLastRound"
                @click="nextQuestion()"
                >Next Question</base-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      :show.sync="showLeaderboard"
      gradient="secondary"
      modal-classes="modal-secondary modal-dialog-centered leaderboard-modal"
    >
      <div slot="header" class="modal-title fa-2x" id="modal-title-default">
        <i class="fa fa-trophy text-gold"></i> Leaderboard
      </div>
      <div class="py-3 text-center">
        <ul class="list-unstyled leaderboard col-sm-12">
          <li
            v-for="(playerScore, index) in playerScores"
            v-bind:key="playerScore.name"
            class="btn btn-secondary row mr-0 ml-0 mb-2"
          >
            <div class="row mr-0 ml-0">
              <div class="pr-2 pl-2">
                <i
                  class="fa fa-2x fa-star"
                  v-bind:class="leaderboardClass(index)"
                  v-if="index < 3"
                ></i>
                <badge :circle="true" type="default" v-if="index > 2">{{
                  index + 1
                }}</badge>
              </div>

              <div class="h5 mb-0 text-left">
                {{ playerScore.name }}
              </div>
              <div class="pr-2 pl-2">
                <badge :circle="true" type="primary">
                  {{ playerScore.score }}
                </badge>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </modal>
  </section>
</template>

<script>
import _ from "lodash";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal
  },

  data() {
    return {
      showLeaderboard: false
    };
  },

  mounted() {
    let isGameStarted = this.$store.state.isGameStarted;
    let isGameEnded = this.$store.state.isGameEnded;

    if (!isGameStarted) {
      console.log("get out of here also!");
      this.$router.push("/waitingRoom");
    } else if (isGameEnded) {
      console.log("game already ended!");
      this.$router.push("/end");
    }

    let currentQuestion = this.$store.state.currentQuestion;
    if (!currentQuestion) {
      this.$socket.emit("getQuestion", (round, question, answers) => {
        this.$store.commit("updateCurrentRound", round);
        this.$store.commit("updateCurrentQuestion", question);
        this.$store.commit("updateCurrentAnswers", answers);
      });
    }
  },

  methods: {
    selectedAnswer(index) {
      this.$socket.emit("selectedAnswer", index);
      this.$store.commit("updateCurrentSelected", index);
    },

    endRound() {
      this.$socket.emit("endRound");
    },

    nextQuestion() {
      this.$socket.emit("nextQuestion");
    },

    endGame() {
      console.log("game ended!");
      this.$socket.emit("endGame");
    },

    getType(index, override) {
      if ((this.isRoundEnded || this.currentSelected) && !override) {
        if (this.isRoundEnded && this.correctAnswer == index) return "success";
        else if (this.currentSelected == index) return "danger";
        return "secondary";
      }
      switch (index) {
        case "a":
          return "primary";
        case "b":
          return "success";
        case "c":
          return "info";
        case "d":
          return "warning";
        default:
          return "default";
      }
    },

    getAnswerPercentage(index) {
      let indexTotal = this.playerAnswers[index];
      return this.totalAnswered > 0
        ? Math.floor((indexTotal / this.totalAnswered) * 100)
        : 0;
    },

    leaderboardClass(index) {
      if (index === 0) return "text-gold";
      if (index === 1) return "text-silver";
      if (index === 2) return "text-bronze";
    }
  },

  computed: {
    roomCode() {
      return this.$store.state.roomCode;
    },
    isAdmin() {
      return this.$store.state.isAdmin;
    },
    isGameStarted() {
      return this.$store.state.isGameStarted;
    },
    isReady() {
      return this.$store.state.isReady;
    },
    gameName() {
      return this.$store.state.gameName;
    },
    currentRound() {
      return this.$store.state.currentRound;
    },
    totalRounds() {
      return this.$store.state.totalRounds;
    },
    currentQuestion() {
      return this.$store.state.currentQuestion;
    },
    currentAnswers() {
      return this.$store.state.currentAnswers;
    },
    currentSelected() {
      return this.$store.state.currentSelected;
    },
    playerAnswers() {
      return this.$store.state.playerAnswers;
    },
    totalAnswered() {
      return _.sum(_.values(this.playerAnswers));
    },
    totalPlayers() {
      return Object.keys(this.$store.state.players).length;
    },
    isRoundEnded() {
      return this.$store.state.isRoundEnded;
    },
    correctAnswer() {
      return this.$store.state.correctAnswer;
    },
    playerScores() {
      return this.$store.state.playerScores;
    },
    playerName() {
      if (this.isAdmin) return "Admin";
      let player = this.$store.state.players[this.$socket.id];
      return player ? player.name : "Spectator";
    },
    isLastRound() {
      return this.$store.state.isLastRound;
    }
  },

  sockets: {
    currentQuestion() {
      this.showLeaderboard = false;
    },
    gameEnded(gameEndedData) {
      if (gameEndedData[0]) {
        this.$router.push("/end");
      }
    }
  }
};
</script>

<style>
.text-gold {
  color: rgb(255, 217, 0);
}

.text-silver {
  color: rgb(196, 196, 196);
}

.text-bronze {
  color: rgb(177, 139, 69);
}

.va-m {
  vertical-align: middle;
}

.leaderboard li {
  display: block;
}

.leaderboard .row {
  justify-content: space-between;
}

.leaderboard .btn {
  text-transform: none;
}

.leaderboard .btn:not(:last-child) {
  margin-right: 0;
}

.leaderboard-modal .modal-header .close span:not(.sr-only) {
  color: black !important;
  font-size: 30px;
}

.leaderboard-modal #modal-title-default {
  margin: auto;
  margin-right: 0;
}

.unset-whitespace {
  white-space: unset !important;
}

.fh {
  height: 100% !important;
}
</style>
