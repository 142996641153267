<template>
  <section
    class="section section-shaped section-hero my-0"
    v-if="isReady || isAdmin || isGameStarting"
  >
    <div class="container align-items-center">
      <div class="col px-0">
        <div class="row justify-content-center">
          <div class="col-sm-12 text-center">
            <p class="text-white h2">
              {{ gameName }}
            </p>
            <p class="text-white lead" v-if="isAdmin">
              Enter
              <span
                class="h4 font-weight-600 text-warning letter-spacing-wide"
                >{{ roomCode }}</span
              >
              to join in on the fun!
            </p>
            <p v-if="!isAdmin">
              <span class="h4 font-weight-600 text-warning letter-spacing-wide"
                >{{ roomCode }}
              </span>
            </p>
            <p>
              <base-button
                v-if="isAdmin"
                type="success"
                :disabled="!Object.keys(players).length"
                @click="startGame"
                >Start Game
              </base-button>
            </p>
            <card type="secondary">
              <p class="text-muted h2">
                {{ Object.keys(players).length }} Players
              </p>
              <ul class="list-unstyled">
                <li
                  v-for="(player, _, index) in players"
                  v-bind:key="player.id"
                  class="player-list h4"
                >
                  <badge pill :type="getType(index)">{{ player.name }}</badge>
                </li>
              </ul>
            </card>
          </div>
        </div>
      </div>
    </div>

    <modal
      :show="showStartingModal"
      gradient="info"
      modal-classes="modal-info modal-dialog-centered"
    >
      <div class="py-3 text-center">
        <i class="ni ni-satisfied ni-3x fas fa-spin"></i>
        <h3 class="text-white mt-4">Game Starting!</h3>
        <h1 class="text-white">
          {{ startingIn > 0 ? startingIn : "Let's Go!" }}
        </h1>
      </div>
    </modal>
  </section>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  mounted() {
    let isGameStarted = this.$store.state.isGameStarted;
    if (isGameStarted) {
      console.log("game already started!");
      this.showStartingModal = false;
      this.$router.push("/game");
    }

    let isReady = this.$store.state.isReady;
    let isAdmin = this.$store.state.isAdmin;
    let isGameStarting = this.$store.state.isGameStarting;
    if (!isReady && !isAdmin && !isGameStarting) {
      console.log("get out of here!");
      this.$router.push("/");
    }
  },

  components: {
    Modal
  },

  computed: {
    gameName() {
      return this.$store.state.gameName;
    },
    roomCode() {
      return this.$store.state.roomCode;
    },
    players() {
      return this.$store.state.players;
    },
    isReady() {
      return this.$store.state.isReady;
    },
    isAdmin() {
      return this.$store.state.isAdmin;
    },
    startingIn() {
      return this.$store.state.startingIn;
    },
    isGameStarting() {
      return this.$store.state.isGameStarting;
    },
    isGameStarted() {
      return this.$store.state.isGameStarted;
    },
    showStartingModal: function() {
      return this.isGameStarting && !this.isGameStarted;
    }
  },

  methods: {
    startGame() {
      console.log("starting game!");
      this.$socket.emit("startGame");
    },

    goTo(page) {
      this.$router.push(`/${page}`);
    },

    getType(index) {
      let i = index % 6;
      switch (i) {
        case 0:
          return "default";
        case 1:
          return "danger";
        case 2:
          return "primary";
        case 3:
          return "warning";
        case 4:
          return "info";
        case 5:
          return "success";
      }
    }
  },

  sockets: {
    gameStarted(gameStarted) {
      if (gameStarted) {
        this.showStartingModal = false;
        setTimeout(() => {
          this.$router.push("/game");
        }, 0);
      }
    }
  }
};
</script>

<style>
.player-list {
  display: inline-flex;
  padding: 5px 20px 5px 20px;
}

.letter-spacing-wide {
  padding-left: 8px;
  letter-spacing: 8px;
  background: black;
  border-radius: 10px;
  font-weight: 600;
}
</style>
