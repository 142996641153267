<template>
  <section class="section section-shaped section-hero my-0">
    <div class="container shape-container align-items-center">
      <div class="row justify-content-center align-items-center">
        <div class="col-sm-5 text-center">
          <card
            type="secondary"
            shadow
            header-classes="bg-white pb-5"
            body-classes="px-sm-5 py-sm-5"
            class="border-0"
          >
            <template>
              <div class="text-center mb-4 h4">
                Welcome to Trivia!
              </div>
              <form role="form" @submit.prevent="readyUp">
                <base-input
                  alternative
                  class="mb-3"
                  placeholder="Name"
                  addon-left-icon="ni ni-circle-08"
                  v-model="name"
                  maxlength="20"
                  :error="nameError"
                >
                </base-input>
                <base-input
                  alternative
                  class="mb-3"
                  placeholder="Room Code"
                  addon-left-icon="fa fa-code"
                  inputClasses="text-uppercase"
                  v-model="code"
                  maxlength="4"
                  :error="codeError"
                >
                </base-input>
                <div class="text-center">
                  <base-button
                    type="primary"
                    class="my-4"
                    icon="ni ni-spaceship"
                    @click.prevent="joinGame"
                    :disabled="!name"
                  >
                    Let's go!
                  </base-button>
                </div>
                <div class="text-center">
                  <base-button
                    type="warning"
                    icon="fa fa-hotel"
                    @click.prevent="showHostModal = true"
                  >
                    Host New Game
                  </base-button>
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>

    <modal
      :show.sync="showHostModal"
      gradient="secondary"
      modal-classes="modal-secondary modal-dialog-centered modal-lg"
    >
      <div class="py-1">
        <p class=" text-center h2">Host New Game</p>
        <div class="row py-2">
          <div class="col-6">
            <input
              class=""
              type="file"
              @change="handleFileChange"
              accept=".json"
            />
          </div>

          <div id="roundTime" class="col-6">
            Round Time (Seconds):
            <input
              class="pl-2"
              type="number"
              placeholder="0-300"
              value="60"
              v-model="roundTime"
              min="0"
              max="300"
            />
          </div>
        </div>

        <p class="mb-0">
          <textarea
            class="w-100 h6 h-300-px"
            v-model="gameJsonString"
            v-on:blur="formatJson"
          />
        </p>
        <div class="text-center small text-danger pb-2" v-show="jsonHasError">
          Invalid JSON!
        </div>
        <div class="text-center small text-danger pb-2" v-show="roundTimeError">
          Invalid round time!
        </div>

        <div class="text-center">
          <base-button
            type="info"
            icon="fa fa-hotel"
            @click.prevent="createGame"
          >
            Create Game
          </base-button>
        </div>
      </div>
    </modal>
  </section>
</template>
<script>
import Modal from "../components/Modal";

export default {
  components: {
    Modal
  },
  mounted() {
    let isGameStarted = this.$store.state.isGameStarted;
    let isGameStarting = this.$store.state.isGameStarting;
    let isGameEnded = this.$store.state.isGameEnded;

    if (isGameStarted) {
      this.$router.push("/game");
    } else if (isGameStarting) {
      this.$router.push("/waitingRoom");
    } else if (isGameEnded) {
      this.$router.push("/end");
    }
  },

  data: function() {
    return {
      name: "",
      nameError: "",
      code: "",
      codeError: "",
      roundTime: 60,
      roundTimeError: "",
      showHostModal: false,
      gameJsonString: this.getExampleJson(),
      gameJson: null,
      jsonHasError: false
    };
  },
  methods: {
    readyUp() {
      this.nameError = "";
      this.$socket.emit("playerReady", this.name, exists => {
        if (exists) {
          this.nameError = "Name already taken!";
          return;
        } else {
          this.$store.commit("updateReady", true);
          this.$router.push("waitingRoom");
        }
      });
    },
    joinGame() {
      this.nameError = "";
      this.codeError = "";

      this.$socket.emit("joinGame", {
        code: this.code,
        name: this.name
      });
    },
    createGame() {
      this.jsonHasError = false;
      this.roundTimeError = false;
      let roundTime = 0;

      try {
        roundTime = parseInt(this.roundTime);
        if (!roundTime || roundTime < 0 || roundTime > 300) {
          this.roundTimeError = true;
          return;
        }

        let gameJson = JSON.parse(this.gameJsonString);
        if (
          !gameJson.name ||
          !gameJson.questions ||
          !gameJson.questions.length
        ) {
          this.jsonHasError = true;
          return;
        }

        for (let question of gameJson.questions) {
          if (
            !question.q ||
            !question.a ||
            !question.answers ||
            !Object.keys(question.answers).includes(question.a)
          ) {
            this.jsonHasError = true;
            return;
          }
        }

        this.gameJson = gameJson;
      } catch (error) {
        this.jsonHasError = true;
        return;
      }

      this.gameJson.roundTime = roundTime;
      this.$socket.emit("createGame", this.gameJson);
    },
    handleFileChange(e) {
      this.jsonHasError = false;

      let reader = new FileReader();
      reader.readAsText(e.target.files[0], "UTF-8");
      reader.onload = evt => {
        this.gameJsonString = JSON.stringify(
          JSON.parse(evt.target.result),
          null,
          4
        );
      };
      reader.onerror = evt => {
        console.error(evt);
        this.jsonHasError = true;
      };
    },
    formatJson() {
      try {
        this.gameJsonString = JSON.stringify(
          JSON.parse(this.gameJsonString),
          null,
          4
        );
      } catch (error) {
        console.log(error);
      }
    },
    getExampleJson() {
      let text = `{ "name": "Insert Trivia Name", "questions": [ { "q": "Example question 1?", "answers": { "a": "Answer A", "b": "Answer B", "c": "Answer C", "d": "Answer D" }, "a": "d" } ] }`;
      return JSON.stringify(JSON.parse(text), null, 4);
    }
  },
  sockets: {
    gameCreated() {
      this.$gtag.event("game_created");

      this.$router.push("/waitingRoom");
    },
    joinGame(data) {
      if (!data.codeError || data.nameError) {
        this.$gtag.event("game_joined", {
          event_category: "users",
          event_label: "name",
          value: this.name
        });

        this.$router.push("/waitingRoom");
      } else {
        this.codeError = data.codeError;
        this.nameError = data.nameError;
      }
    },
    gameStarted(gameStarted) {
      if (gameStarted) {
        this.$router.push("/game");
      }
    },
    gameStarting(gameStarting) {
      if (gameStarting) {
        this.$router.push("/waitingRoom");
      }
    }
  }
};
</script>

<style>
input .form-control[placeholder="Name"] {
  font-size: 20px !important;
}

.h-300-px {
  height: 300px;
}

#roundTime {
  text-align: right;
}
</style>
