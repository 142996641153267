import Vue from "vue";
import "es6-promise/auto";
import Vuex from "vuex";

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    isConnected: false,
    isReady: false,
    id: "",
    players: {},
    isAdmin: false,
    isGameStarting: false,
    isGameStarted: false,
    startingIn: 5,
    gameName: "",
    currentRound: 1,
    totalRounds: 1,
    currentQuestion: "",
    currentAnswers: {},
    currentSelected: "",
    playerAnswers: {},
    isRoundEnded: false,
    correctAnswer: "",
    playerScores: [],
    isLastRound: false,
    isGameEnded: false,
    roomCode: ""
  };
};

const store = new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    resetState: state => {
      let id = state.id;
      for (const [key, value] of Object.entries(getDefaultState())) {
        state[key] = value;
      }
      state.id = id;
    },
    updateIsConnected: (state, isConnected) => {
      state.isConnected = isConnected;
    },
    updateId: (state, id) => {
      state.id = id;
    },
    updateReady: (state, ready) => {
      state.isReady = ready;
    },
    updatePlayers: (state, players) => {
      state.players = players;
    },
    updateAdmin: (state, isAdmin) => {
      state.isAdmin = isAdmin;
    },
    updateGameStarted: (state, isGameStarted) => {
      state.isGameStarted = isGameStarted;
    },
    updateGameStarting: (state, isGameStarting) => {
      state.isGameStarting = isGameStarting;
    },
    updateStartingIn: (state, startingIn) => {
      state.startingIn = startingIn;
    },
    updateGameName: (state, gameName) => {
      state.gameName = gameName;
    },
    updateCurrentRound: (state, currentRound) => {
      state.currentRound = currentRound;
    },
    updateTotalRounds: (state, totalRounds) => {
      state.totalRounds = totalRounds;
    },
    updateCurrentQuestion: (state, currentQuestion) => {
      state.currentQuestion = currentQuestion;
    },
    updateCurrentAnswers: (state, currentAnswers) => {
      state.currentAnswers = currentAnswers;
    },
    updateCurrentSelected: (state, currentSelected) => {
      state.currentSelected = currentSelected;
    },
    updatePlayerAnswers: (state, playerAnswers) => {
      state.playerAnswers = playerAnswers;
    },
    updateRoundEnded: (state, isRoundEnded) => {
      state.isRoundEnded = isRoundEnded;
    },
    updateCorrectAnswer: (state, correctAnswer) => {
      state.correctAnswer = correctAnswer;
    },
    updatePlayerScores: (state, playerScores) => {
      state.playerScores = playerScores;
    },
    updateIsLastRound: (state, isLastRound) => {
      state.isLastRound = isLastRound;
    },
    updateIsGameEnded: (state, isGameEnded) => {
      state.isGameEnded = isGameEnded;
    },
    updateRoomCode: (state, code) => {
      state.roomCode = code;
    },

    SOCKET_playersUpdated(state, data) {
      // CONNNECTED(id, player), DISCONNECTED(id)
      switch (data[0]) {
        case "CONNECTED":
          state.players[data[1]] = data[2];
          state.players = Object.assign({}, state.players);
          break;
        case "DISCONNECTED":
          if (!state.game || !state.isGameStarted) {
            delete state.players[data[1]];
            state.players = Object.assign({}, state.players);
          } else {
            state.players[data[1]].disconnected = true;
            state.players = Object.assign({}, state.players);
          }
          break;
        default:
          console.log("unhandled event!", data[0]);
          break;
      }
    },
    SOCKET_gameCreated(state, g) {
      state.gameName = g.gameQuestions.name;
      state.totalRounds = g.game.totalRounds;
      state.roomCode = g.code.toUpperCase();
      state.isAdmin = true;
    },
    SOCKET_joinGame(state, g) {
      if (!g.codeError || g.nameError) {
        state.players = g.players;
        state.roomCode = g.code.toUpperCase();
        state.isReady = !g.gameStarting;

        state.gameName = g.gameQuestions.name;
        state.isGameEnded = g.gameEnded;
        state.isGameStarted = g.gameStarted;
        state.isGameStarting = g.gameStarting;
        state.totalRounds = g.game.totalRounds;
        state.startingIn = g.startingIn;
        state.currentRound = g.game.currentRound;

        let { q, answers } = g.gameQuestions.questions[g.game.currentRound - 1];
        state.currentQuestion = q;
        state.currentAnswers = answers;
        state.isLastRound = g.game.currentRound == g.game.totalRounds;
      }
    }
  }
});

export default store;
