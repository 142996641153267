import Vue from "vue";
import App from "./App.vue";
import socketio from "socket.io-client";
import VueSocketIO from "vue-socket.io";
import store from "./store/store";
import router from "./router";
import Argon from "./plugins/argon-kit";
import VueConfetti from "vue-confetti";
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

export const SocketInstance = socketio(process.env.VUE_APP_SERVER_HOSTNAME);

Vue.use(
  new VueSocketIO({
    connection: SocketInstance,
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_"
    }
  })
);

Vue.use(VueConfetti);

Vue.use(Argon);

Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GTAG },
    appName: "Trivia Night",
    pageTrackerScreenviewEnabled: true
  },
  router
);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
